import { CommonModule } from '@angular/common';
import { booleanAttribute, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IconComponent, IconType } from '../icon/icon.component';
import { LoaderComponent } from '@core/components/states/loader/loader.component';

export type ColorType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'none'
  | 'primary-inline'
  | 'toggle'
  | 'toggle-off';

@Component({
  selector: '[pd-button]',
  standalone: true,
  imports: [LoaderComponent, IconComponent, CommonModule],
  template: `
    <pd-loader class="mx-1" *ngIf="showLoader" [color]="loaderColor" size="xs"></pd-loader>
    <pd-icon [type]="iconType" class="mx-1 min-w-[14px]" *ngIf="!loading && icon" [icon]="icon"></pd-icon>
    <ng-content></ng-content>
  `,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input()
  public color: ColorType = 'primary';
  @Input()
  public loading: boolean | Observable<any> = false;
  @Input({ transform: booleanAttribute })
  public inline: boolean = false;

  @Input({ transform: booleanAttribute })
  public active: boolean | Observable<any>;
  /**
   * Icon to use provided by Font Awesome. You can omit the fa- prefix as it will be included.
   * You can find icons here: https://fontawesome.com/search?o=r&m=free
   * Sometimes you may need to keep in mind the type of icon and have to change it accordingly.
   */
  @Input()
  public icon: string;
  @Input()
  public iconType: IconType;

  @HostBinding()
  protected class: string[];

  protected showLoader: boolean = false;
  protected loaderColor: ColorType = 'secondary';
  protected baseClasses: string[] = ['rounded', 'flex', 'items-center', 'justify-center'];
  protected customClasses: string[] = [];

  public constructor() {}

  public ngOnInit(): void {
    if (this.inline) {
      this.customClasses.push('px-1', 'min-h-[2.5rem]');
    } else {
      this.customClasses.push('px-4', 'min-h-[58px]');
    }
    if (this.color === 'toggle') {
      this.class = this.getClassesByColor(this.active ? 'toggle' : 'toggle-off');
    } else {
      this.class = this.getClassesByColor(this.color);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const currentColor = changes['color']?.currentValue ?? this.color;
    if (currentColor === 'toggle') {
      this.class = this.getClassesByColor(this.active ? 'toggle' : 'toggle-off');
    } else {
      this.class = this.getClassesByColor(currentColor);
    }
    this.determineLoaderState();
  }

  private determineLoaderState(): void {
    if (this.loading instanceof Observable) {
      this.showLoader = true;
      const sub: Subscription = this.loading.subscribe({
        next: () => {
          this.showLoader = false;
          sub.unsubscribe();
        },
        error: () => {
          this.showLoader = false;
          sub.unsubscribe();
        },
      });
    } else {
      this.showLoader = this.loading;
    }
  }

  protected getClassesByColor(color: ColorType): string[] {
    switch (color) {
      case 'primary':
        this.loaderColor = 'secondary';
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-primary',
          'dark:bg-primary-light',
          'text-white',
          'font-semibold',
          'border',
          'border-transparent',
          'enabled:hover:bg-primary-dark',
          'enabled:dark:hover:bg-primary',
          'disabled:opacity-50',
        ];
      case 'secondary':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-transparent',
          'border',
          'font-semibold',
          'border-dynamic',
          'text-black',
          'enabled:hover:bg-gray-200',
          'dark:enabled:hover:bg-gray-900',
          'dark:text-gray-200',
          'disabled:text-gray-500',
          'disabled:opacity-50',
        ];
      case 'success':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-green-500',
          'text-white',
          'font-semibold',
          'border',
          'border-transparent',
          'enabled:hover:bg-green-700',
          'disabled:bg-opacity-40',
        ];
      case 'warning':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-yellow-500',
          'text-mat-gray-light',
          'font-semibold',
          'border',
          'border-transparent',
          'enabled:hover:bg-yellow-600',
          'disabled:bg-opacity-40',
        ];
      case 'danger':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-red-500',
          'text-mat-gray-light',
          'font-semibold',
          'border',
          'border-transparent',
          'enabled:hover:bg-red-600',
          'disabled:bg-opacity-40',
        ];
      case 'none':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-transparent',
          'font-semibold',
          'text-black',
          'enabled:hover:bg-gray-200',
          'dark:enabled:hover:bg-gray-900',
          'dark:text-gray-200',
          'disabled:text-gray-500',
          'disabled:bg-gray-100',
        ];
      case 'primary-inline':
        return [...this.baseClasses, ...this.customClasses, 'font-semibold', 'text-primary', 'dark:text-primary-light'];
      case 'toggle':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-faded-50-primary-light',
          'border',
          'font-semibold',
          'text-primary',
          'border-primary-light',
          'enabled:hover:bg-gray-200',
          'dark:enabled:hover:bg-gray-900',
          'dark:text-primary-dark-200',
          'dark:bg-faded-50-primary-dark',
          'dark:border-primary-dark',
          'disabled:text-gray-500',
          'disabled:opacity-50',
          'rounded-lg',
        ];
      case 'toggle-off':
        return [
          ...this.baseClasses,
          ...this.customClasses,
          'bg-transparent',
          'border',
          'font-semibold',
          'text-black',
          'border-gray-200',
          'enabled:hover:bg-gray-200',
          'dark:enabled:hover:bg-gray-900',
          'dark:text-gray-200',
          'disabled:text-gray-500',
          'disabled:opacity-50',
          'rounded-lg',
        ];
    }
  }
}
