import { OnChanges, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
export type IconType = 'brands' | 'light' | 'regular' | 'solid';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'pd-icon',
  template: '<img *ngIf="image" [src]="image" />',
})
export class IconComponent implements OnChanges {
  @Input()
  public icon: string;
  @Input()
  public image: string;
  @Input()
  public type: IconType = null;
  @Input()
  public iconStyle: 'sharp' | null = null;
  @HostBinding()
  protected class: string[] | string;
  public constructor() {}
  private baseClasses: string = 'm-1';

  public ngOnChanges(): void {
    if (this.icon) {
      this.class = this.getClassesString(this.icon);
    }
  }

  private getClassesString(classToAdd: string): string {
    let prefix: string = 'fa';
    if (this.type) {
      prefix = `${prefix} fa-${this.type}`;
    }
    if (this.iconStyle) {
      prefix = `${prefix} fa-${this.iconStyle}`;
    }
    prefix = `${prefix} fa-`;
    return `${this.baseClasses} ${prefix}${classToAdd}`;
  }
}
