import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@core/components/buttons/button.component';

@Component({
  selector: 'pd-header',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './header.component.html',
  styles: [' :host { display: flex; }'],
})
export class HeaderComponent {
  @Input()
  public closable: boolean = true;

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();
}
